import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, Grid } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  backgroundColor: "#262535",
  boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
  border: "1px solid #686DFC",
};

export default function popupModal({
  text,
  cross,
  open,
  handleClose,
  handleLogoutConfirm,
}) {
  return (
    <Grid container justifyContent="center" sx={{ width: "50%" }}>
      <Grid item xs={10} sm={8} md={6} lg={4}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              sx={{
                color: "white",
                marginBottom: 2,
                textAlign: "center",
              }}
            >
              {text.title}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{
                marginBottom: 2,
                color: "white",
                textAlign: "center",
              }}
            >
              {text.content}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleLogoutConfirm}
                sx={{
                  marginRight: 2,

                  "&:hover, &:focus": {
                    backgroundColor: "#5a5ffa",
                    color: "white",
                  },

                  backgroundColor: "#686DFC",
                }}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
              >
                No
              </Button>
            </Box>
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
}
