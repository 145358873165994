import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Fade from "@mui/material/Fade";
import { Box } from "@mui/material";

const HomeAccordion = ({ text }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Accordion
        expanded={expanded}
        onChange={handleExpansion}
        slots={{ transition: Fade }}
        slotProps={{ transition: { timeout: 400 } }}
        sx={{
          marginY: "8px",
          "& .MuiAccordion-region": { height: expanded ? "auto" : 0 },
          "& .MuiAccordionDetails-root": {
            display: expanded ? "block" : "none",
          },
          backgroundColor: "#2B2C40",
          color: "white",
          boxShadow: "0.5px 0.5px 1px 1px rgba(0,0,0,0.2)",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            backgroundColor: expanded ? "#686DFC" : "#2B2C40",
            "&:hover": {
              boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
              backgroundColor: "#686DFC",
              color: "white",
            },

            borderTopRightRadius: expanded ? "5px" : "5px",
            borderTopLeftRadius: expanded ? "5px" : "5px",
            borderBottomRightRadius: expanded ? "0px" : "5px",
            borderBottomLeftRadius: expanded ? "0px" : "5px",
            borderColor: "#2B2C40",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Recoletabold",
              fontSize: { xs: "17px", xl: "24px" },
            }}
          >
            {text?.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#5659CD",
            color: "white",
            borderBottomRightRadius: "5px",
            borderBottomLeftRadius: "5px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: 400,
              fontSize: { xs: "15px", xl: "20px" },
            }}
          >
            {text?.content}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default HomeAccordion;
