import * as React from "react";
import { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../App";

const defaultTheme = createTheme();

const SignIn = () => {
  const { isAuthenticated, login, logout } = useAuth();
  const [disableSignInButton, setDisableSignInButton] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isVerified, setIsVerified] = useState(false);
  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  useEffect(() => {
    const code = searchParams.get("code");
    if (code && !isVerified) {
      // Only execute if code exists and not already verified
      codeVerification(code);
    }
  }, [searchParams, isVerified]); // Include isVerified in the dependency array

  const codeVerification = async (code) => {
    try {
      const formData = new FormData();
      formData.append("code", code);
      const response = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "optverify",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data) {
        const { token } = response.data;
        localStorage.setItem("token", token);
        showSuccessToastMessage("Welcome back!");
        login();
        setIsVerified(true);
        navigate("/");
      }
    } catch (error) {
      showErrorToastMessage("Error! please try again");
    }
  };

  const formik = new useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string().min(5).required("Required"),
    }),

    onSubmit: async (values) => {
      try {
        const formData = new FormData();

        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
        setDisableSignInButton(true);
       
        const response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + "login",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response?.data) {
          localStorage.setItem("token", response?.data?.token);
          showSuccessToastMessage("Welcome back!");
          login();
          navigate("/");
          setDisableSignInButton(false);
        }
      } catch (error) {
        if (error?.response?.data?.emailError) {
          showErrorToastMessage("Invalid email!");
        } else if (error?.response?.data?.passwordError) {
          showErrorToastMessage("Invalid password!");
        } else {
          showErrorToastMessage("Error! please try again");
        }

        setEmailError(error?.response?.data?.emailError);
        setPasswordError(error?.response?.data?.passwordError);
        localStorage.removeItem("token");
        setDisableSignInButton(false);
      }
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          minHeight: "100vh",
          maxHeight: { xs: 1000, sm: 1150 },
          backgroundColor: "#222131",
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ paddingY: 4 }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 4,
              boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
              border: "1px solid #686DFC",
              padding: "30px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#222131",
              color: "white",
            }}
          >
            <Link to={"/"}>
              <Avatar
                alt="Logo"
                sx={{ m: 2, width: 65, height: 65, cursor: "pointer" }}
                src="Magic Pleadings.png"
              />
            </Link>
            <Typography
              component="h1"
              variant="h5"
              sx={{ fontFamily: "Recoletabold" }}
            >
              Here's you can <span style={{ color: "#686DFC" }}>login </span>
            </Typography>
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                error={
                  (formik.touched.email && formik.errors?.email) || emailError
                }
                margin="normal"
                required
                fullWidth
                id="email"
                size="small"
                variant="filled"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                helperText={
                  (formik.touched?.email && formik.errors?.email) || emailError
                }
                sx={{ backgroundColor: "white", borderRadius: "5px" }}
              />
              <TextField
                error={
                  (formik.touched.password && formik.errors?.password) ||
                  passwordError
                }
                margin="normal"
                required
                fullWidth
                variant="filled"
                name="password"
                label="Password"
                type="password"
                size="small"
                id="password"
                autoComplete="current-password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                helperText={
                  (formik.touched?.password && formik.errors?.password) ||
                  passwordError
                }
                sx={{ backgroundColor: "white", borderRadius: "5px" }}
              />

              <Grid container>
                <Grid
                  item
                  xs
                  sx={{ fontSize: { xs: "16px" }, marginTop: "10px" }}
                >
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="remember"
                            sx={{ color: "white", paddingY: "0px" }}
                            color="primary"
                          />
                        }
                        label="Remember me"
                      />
                    </Grid>
                    <Grid item>
                      <Link
                        to={"/forget-password"}
                        style={{ color: "white" }}
                        variant="body2"
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={disableSignInButton}
                sx={{
                  fontFamily: "poppins",
                  mt: 3,
                  mb: 2,

                  boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                  "&:hover, &:focus": {
                    boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                    backgroundColor: "white",
                    color: "#6a1b9a",
                  },

                  ":disabled": {
                    boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                    backgroundColor: "white",
                    color: "#6a1b9a",
                  },
                  backgroundColor: "#686DFC",
                  fontWeight: "800",
                }}
              >
                Login
              </Button>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  sx={{
                    fontSize: { xs: "16px", sm: "16px" },
                  }}
                >
                  Don't have an account?
                  <Link
                    to={"/register"}
                    style={{
                      color: "white",
                      fontSize: "15px",
                    }}
                    variant="body2"
                  >
                    {" Register"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default SignIn;
