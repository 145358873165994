import React, { useEffect, useRef } from "react";
import { MenuButton } from "@mui/joy";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import { Link } from "react-router-dom";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { Box } from "@mui/material";
import IframeData from "../../../pages/Iframes/IframeData";
export default function DropDown({ logoutAlert }) {
  const [openStudents, setOpenStudents] = React.useState(false);
  const [openPleadings, setOpenPleadings] = React.useState(false);
  const [openMedical, setOpenMedical] = React.useState(false);
  const buttonStudentRef = useRef(null);
  const buttonPleadingsRef = useRef(null);
  const buttonMedicalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonStudentRef.current &&
        !buttonStudentRef.current.contains(event.target)
      ) {
        setOpenStudents(false);
      }

      if (
        buttonPleadingsRef.current &&
        !buttonPleadingsRef.current.contains(event.target)
      ) {
        setOpenPleadings(false);
      }

      if (
        buttonMedicalRef.current &&
        !buttonMedicalRef.current.contains(event.target)
      ) {
        setOpenMedical(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleStudentClick = (event) => {
    event.stopPropagation();
    setOpenStudents(!openStudents);
    setOpenMedical(false);
    setOpenPleadings(false);
  };
  const handlePleadingsClick = (event) => {
    event.stopPropagation();
    setOpenPleadings(!openPleadings);
    setOpenStudents(false);
    setOpenMedical(false);
  };
  const handleMedicalClick = (event) => {
    event.stopPropagation();
    setOpenMedical(!openMedical);
    setOpenStudents(false);
    setOpenPleadings(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginX: "5px",
      }}
      gap={1}
    >
      <Dropdown>
        <Link
          to={`/`}
          style={{
            textDecoration: "none",
            color: "black",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              color: "gray",
            },
          }}
        >
          <MenuButton
            sx={{
              fontWeight: 400,
              fontFamily: "poppins",
              fontSize: { xs: "15px", xl: "20px" },
              display: { xs: "none", md: "block" },
              border: "none",
              color: "white",
              "&:hover": {
                backgroundColor: "#686DFC", // Add your hover color here
              },
            }}
          >
            Home
          </MenuButton>
        </Link>
      </Dropdown>
      <Dropdown>
        <MenuButton
          sx={{
            fontFamily: "poppins",
            fontSize: { xs: "15px", xl: "20px" },
            border: "none",
            color: "white",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#686DFC",
            },
            paddingRight: "7px",
            display: { xs: "none", md: "flex" },
            fontWeight: 400,
          }}
          ref={buttonStudentRef}
          onClick={handleStudentClick}
          endDecorator={openStudents ? <ExpandLess /> : <ExpandMore />}
        >
          Students
        </MenuButton>
        <Menu
          sx={{
            border: "none",
            padding: 1,

            "--ListItemDecorator-size": "20px",
            backgroundColor: "linear-gradient(to right, #686DFC, #758BFD)",
          }}
          placement="bottom-start" // Set placement to bottom-end
        >
          {IframeData.IframeStudentData.map((doc) => {
            return (
              <Link
                key={doc.id}
                to={`/students/${doc.id}`}
                style={{
                  textDecoration: "none",
                  color: "gray",
                  fontFamily: "poppins",
                }}
              >
                <MenuItem
                  sx={{
                    transition: "background-color 0.3s ease-in-out",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "gray",
                    },
                  }}
                >
                  {doc.title}
                </MenuItem>
              </Link>
            );
          })}
          {/* <Link
            to="/memorandum"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Single Issue Supporting Memorandum
            </MenuItem>
          </Link>

          <Link
            to="/assignment-of-error"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Single Assignment of Error
            </MenuItem>
          </Link>

          <Link
            to="/motion-for-reconsideration"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Memorandum in Support of Motion for Reconsideration
            </MenuItem>
          </Link>

          <Link
            to="/medical-visit-summary"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Medical Visit Summary
            </MenuItem>
          </Link>

          <Link
            to="/statement-of-material-facts"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Statement of Material Facts
            </MenuItem>
          </Link>

          <Link
            to="/written-reasons-for-judgment"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Written Reasons for Judgment
            </MenuItem>
          </Link> */}

          {/* <Link
            to="/case-law-irac-analysis"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Case Law IRAC Analysis
            </MenuItem>
          </Link> */}

          {/* <Link
            to="/medical-visit-report"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Medical Visit Report
            </MenuItem>
          </Link> */}
        </Menu>
      </Dropdown>
      <Dropdown>
        <MenuButton
          sx={{
            fontFamily: "poppins",
            fontSize: { xs: "15px", xl: "20px" },
            border: "none",
            color: "white",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#686DFC",
            },
            paddingRight: "7px",
            display: { xs: "none", md: "flex" },
            fontWeight: 400,
          }}
          ref={buttonPleadingsRef}
          onClick={handlePleadingsClick}
          endDecorator={openPleadings ? <ExpandLess /> : <ExpandMore />}
        >
          Pleadings
        </MenuButton>
        <Menu
          sx={{
            border: "none",
            padding: 1,

            "--ListItemDecorator-size": "20px",
            backgroundColor: "linear-gradient(to right, #686DFC, #758BFD)",
          }}
          placement="bottom-start" // Set placement to bottom-end
        >
          {IframeData.IframePleadingsData.map((doc) => {
            return (
              <Link
                key={doc.id}
                to={`/pleadings/${doc.id}`}
                style={{
                  textDecoration: "none",
                  color: "gray",
                  fontFamily: "poppins",
                }}
              >
                <MenuItem
                  sx={{
                    transition: "background-color 0.3s ease-in-out",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "gray",
                    },
                  }}
                >
                  {doc.title}
                </MenuItem>
              </Link>
            );
          })}
          {/* <Link
            to="/memorandum"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Single Issue Supporting Memorandum
            </MenuItem>
          </Link>

          <Link
            to="/assignment-of-error"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Single Assignment of Error
            </MenuItem>
          </Link>

          <Link
            to="/motion-for-reconsideration"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Memorandum in Support of Motion for Reconsideration
            </MenuItem>
          </Link> */}

          {/* <Link
            to="/medical-visit-summary"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Medical Visit Summary
            </MenuItem>
          </Link> */}

          {/* <Link
            to="/statement-of-material-facts"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Statement of Material Facts
            </MenuItem>
          </Link>

          <Link
            to="/written-reasons-for-judgment"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Written Reasons for Judgment
            </MenuItem>
          </Link> */}
          {/* 
          <Link
            to="/case-law-irac-analysis"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Case Law IRAC Analysis
            </MenuItem>
          </Link>

          <Link
            to="/medical-visit-report"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Medical Visit Report
            </MenuItem>
          </Link> */}
        </Menu>
      </Dropdown>
      <Dropdown>
        <MenuButton
          sx={{
            fontFamily: "poppins",
            fontSize: { xs: "15px", xl: "20px" },
            border: "none",
            color: "white",
            transition: "background-color 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: "#686DFC",
            },
            paddingRight: "7px",
            display: { xs: "none", md: "flex" },
            fontWeight: 400,
          }}
          ref={buttonMedicalRef}
          onClick={handleMedicalClick}
          endDecorator={openMedical ? <ExpandLess /> : <ExpandMore />}
        >
          Medicals
        </MenuButton>
        <Menu
          sx={{
            border: "none",
            padding: 1,

            "--ListItemDecorator-size": "20px",
            backgroundColor: "linear-gradient(to right, #686DFC, #758BFD)",
          }}
          placement="bottom-start" // Set placement to bottom-end
        >
          {IframeData.IframeMedicalData.map((doc) => {
            return (
              <Link
                key={doc.id}
                to={`/medical/${doc.id}`}
                style={{
                  textDecoration: "none",
                  color: "gray",
                  fontFamily: "poppins",
                }}
              >
                <MenuItem
                  sx={{
                    transition: "background-color 0.3s ease-in-out",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                      color: "gray",
                    },
                  }}
                >
                  {doc.title}
                </MenuItem>
              </Link>
            );
          })}
          {/* <Link
            to="/memorandum"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Single Issue Supporting Memorandum
            </MenuItem>
          </Link>

          <Link
            to="/assignment-of-error"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Single Assignment of Error
            </MenuItem>
          </Link>

          <Link
            to="/motion-for-reconsideration"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Memorandum in Support of Motion for Reconsideration
            </MenuItem>
          </Link> */}

          {/* <Link
            to="/medical-visit-summary"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Medical Visit Summary
            </MenuItem>
          </Link> */}

          {/* <Link
            to="/statement-of-material-facts"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Statement of Material Facts
            </MenuItem>
          </Link>

          <Link
            to="/written-reasons-for-judgment"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Written Reasons for Judgment
            </MenuItem>
          </Link>

          <Link
            to="/case-law-irac-analysis"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Case Law IRAC Analysis
            </MenuItem>
          </Link> */}

          {/* <Link
            to="/medical-visit-report"
            style={{
              textDecoration: "none",
              color: "gray",
              fontFamily: "poppins",
            }}
          >
            <MenuItem
              sx={{
                transition: "background-color 0.3s ease-in-out",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                  color: "gray",
                },
              }}
            >
              Medical Visit Report
            </MenuItem>
          </Link> */}
        </Menu>
      </Dropdown>
      <Dropdown>
        {/* <Link to={"/subscriptions"} style={{ textDecoration: "none" }}>
          <MenuButton
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "15px", xl: "20px" },
              display: { xs: "none", md: "block" },
              fontWeight: 400,
              border: "none",
              color: "white",
              "&:hover": {
                backgroundColor: "#686DFC", // Add your hover color here
              },
            }}
          >
            Subscriptions
          </MenuButton>
        </Link> */}
      </Dropdown>
      <Dropdown>
        <MenuButton
          onClick={logoutAlert}
          sx={{
            fontFamily: "poppins",
            fontSize: { xs: "15px", xl: "20px" },
            display: { xs: "none", md: "block" },
            fontWeight: 400,
            border: "none",
            color: "white",
            "&:hover": {
              backgroundColor: "#686DFC", // Add your hover color here
            },
          }}
        >
          Logout
        </MenuButton>
      </Dropdown>
    </Box>
  );
}
