import { Box, Typography } from "@mui/material";
import ListCardDoc from "./ListDocCard";
import ListDocData from "./ListDocData";

const ListDoc = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#222131",
        paddingY: "30px",
        marginTop: { xs: "70%", sm: "0px" },
      }}
    >
      <Box
        sx={{
          backgroundColor: "#222131",
          backgroundImage: 'url("magic-board.png"), url("magic-board.png")',
          backgroundSize: { xs: "40%, 80%", sm: "40%, 45%" }, // Adjust as needed
          backgroundPosition: {
            xs: "10% 107%, 50% -1%",
            sm: "10% 107%, 90% -5%",
          }, // Adjust as needed
          backgroundRepeat: "no-repeat, no-repeat", // Adjust as needed
          paddingY: "20px",
          paddingBottom: "50px",
        }}
      >
        {ListDocData.ListPleadingsDocData.map((doc) => {
          return (
            <ListCardDoc
              key={doc.id}
              id={doc.id}
              heading={doc.heading}
              title={doc.title}
              linkSrc={doc.linkSrc}
              content={doc.content}
            />
          );
        })}
      </Box>

      <Box
        sx={{
          backgroundColor: "#222131",
          backgroundImage: ' url("magic-board.png")',
          backgroundSize: { xs: "80%", sm: "45%" }, // Adjust as needed
          backgroundPosition: { xs: "50% -4%", sm: "90% -5%" }, // Adjust as needed
          backgroundRepeat: "no-repeat, no-repeat", // Adjust as needed
          paddingY: "20px",
        }}
      >
        {ListDocData.ListMedicalDocData.map((doc) => {
          return (
            <ListCardDoc
              key={doc}
              id={doc.id}
              heading={doc.heading}
              title={doc.title}
              linkSrc={doc.linkSrc}
              content={doc.content}
            />
          );
        })}
      </Box>

      <Box
        sx={{
          backgroundColor: "#222131",
          paddingY: "20px",
        }}
      >
        {ListDocData.ListStudentsDocData.map((doc) => {
          return (
            <ListCardDoc
              key={doc}
              id={doc.id}
              heading={doc.heading}
              title={doc.title}
              linkSrc={doc.linkSrc}
              content={doc.content}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default ListDoc;
