const SamplesData = [
  {
    id: 1,
    title: "Single Assignment of Error",
    imageSrc: "../../../Assignment-of-error-analysis.png",
  },
  {
    id: 2,
    title: "Case Law IRAC Analysis",
    imageSrc: "../../../IRAC.png",
  },
  {
    id: 3,
    title: "Medical Visit Summary",
    imageSrc: "../../../Medicle-Visit-Summary.png",
  },
  {
    id: 4,
    title: "Medical Visit Report",
    imageSrc: "../../../Medicle-Visit-Report.png",
  },

  {
    id: 5,
    title: "Memorandum in Support of Motion for Reconsideration",
    imageSrc:
      "../../../Memorandum-in-support-of-Motion-for-Reconsideration.png",
  },

  {
    id: 6,
    title: "Statement of Material Facts",
    imageSrc: "../../../Statement-of-material.png",
  },
  {
    id: 7,
    title: "Single Issue Supporting Memorandum",
    imageSrc: "../../../content-Two.png",
  },
  {
    id: 8,
    title: "Written Reasons For Judgment",
    imageSrc: "../../../Reason-for-judgment.png",
  },
];

export default SamplesData;
