import "./App.css";
import HomePage from "./pages/Home.js";
import { Routes, Route, useLocation } from "react-router-dom";
import Footer from "./components/Footer/index.js";
import SignUp from "./pages/AUTH/SignUp.js";
import SignIn from "./pages/AUTH/SignIn.js";
import ForgetPassword from "./pages/AUTH/ForgetPassword.js";
import ResetPassword from "./pages/AUTH/ResetPassword.js";
import Terms from "./pages/Terms.js";
import PrivacyAndPolicy from "./pages/PrivacyAndPolicy.js";
import { ToastContainer } from "react-toastify";
import { createContext, useContext, useState } from "react";
import SamplesData from "./pages/Samples/SamplesData.js";
import SamplesDoc from "./pages/Samples/SamplesDoc.js";
import IframeData from "./pages/Iframes/IframeData.js";
import Iframe from "./pages/Iframes/Iframe.js";
import ContactUs from "./pages/ContactUs/ContactUs.js";
// import Subscriptions from "./pages/Subscriptions/index.js";
// import CheckoutForm from "./pages/Checkout/index.js";
import { useDispatch } from "react-redux";
import slices from "./store/user/userSlice.js";
import axios from "axios";

const AuthContext = createContext();

function App() {
  const location = useLocation();
  const pathName = location.pathname;
  const dispatch = useDispatch();

  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("token")
  );
  const login = () => {
    if (
      localStorage.getItem("token") != "undefined" &&
      localStorage.getItem("token") != null
    ) {
      const authenticatedUser = async (token) => {
        try {
          const formData = new FormData();
          formData.append("token", token);
          const response = await axios.post(
            process.env.REACT_APP_BACKEND_URL + "authenticatedUser",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data) {
            dispatch(slices.clearUser());
            dispatch(slices.setUser(response.data));
          }
        } catch (error) {}
      };

      authenticatedUser(localStorage.getItem("token"));

      // Perform authentication logic here, e.g., check credentials, set tokens
      setIsAuthenticated(true);
    }
  };

  const logout = () => {
    dispatch(slices.clearUser());
    // Perform logout logic here, e.g., clear tokens
    setIsAuthenticated(false);
    localStorage.removeItem("token");
  };

  return (
    <>
      <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
        <ToastContainer autoClose={6000} />
        <Routes>
          <Route>
            <Route index element={<HomePage />} />
            {/* student Iframes */}
            {IframeData.IframeStudentData.map((doc) => {
              return (
                <Route
                  key={doc.id}
                  path={`/students/${doc.id}`}
                  element={
                    <Iframe title={doc.title} iframeSrc={doc.iframeSrc} />
                  }
                />
              );
            })}
            {/* pleadings Iframes */}
            {IframeData.IframePleadingsData.map((doc) => {
              return (
                <Route
                  key={doc.id}
                  path={`/pleadings/${doc.id}`}
                  element={
                    <Iframe title={doc.title} iframeSrc={doc.iframeSrc} />
                  }
                />
              );
            })}
            {/* medical Iframes */}
            {IframeData.IframeMedicalData.map((doc) => {
              return (
                <Route
                  key={doc.id}
                  path={`/medical/${doc.id}`}
                  element={
                    <Iframe title={doc.title} iframeSrc={doc.iframeSrc} />
                  }
                />
              );
            })}

            {/* Samples Pleadings components */}
            {SamplesData.map((doc) => {
              return (
                <Route
                  key={doc.id}
                  path={`/samples/${doc.id}`}
                  element={
                    <SamplesDoc title={doc.title} imageSrc={doc.imageSrc} />
                  }
                />
              );
            })}

            {/* Auth components */}
            <Route path="/login" element={<SignIn />} />
            <Route path="/register" element={<SignUp />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/policy" element={<PrivacyAndPolicy />} />
            <Route path="/contact" element={<ContactUs />} />
          </Route>
{/* 
          <Route path="/subscriptions" element={<Subscriptions />} /> */}

          {/* <Route path="/checkout" element={<CheckoutForm />} /> */}
        </Routes>
        {pathName !== "/login" &&
        pathName !== "/register" &&
        pathName !== "/forget-password" &&
        pathName !== "/reset-password" ? (
          <Footer />
        ) : (
          ""
        )}
      </AuthContext.Provider>
    </>
  );
}

export default App;
export const useAuth = () => useContext(AuthContext);
