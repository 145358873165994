import { createSlice } from "@reduxjs/toolkit";

const initialState = [];
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (_, action) => {
      return action.payload;
    },
    clearUser: () => initialState,
  },
});

const { setUser, clearUser } = userSlice.actions;
const reducer = userSlice.reducer;

const slices = {
  setUser: setUser,
  clearUser: clearUser,
  reducer: reducer,
};

export default slices;
