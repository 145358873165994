const ListPleadingsDocData = [
  {
    id: 1,
    heading: "Pleadings",
    title: "Memorandum in Support of Motion for Reconsideration",
    linkSrc: "/samples/5",
    content:
      "The user upload the court's reasons for judgment. The user will also copy and paste the facts, statements of law, and rulings from three judicial decisions and explain why the court erred in its analysis. An AI attorney analyzes the above information and writes a memorandum in support of a motion for reconsideration.",
  },
  {
    id: 2,
    heading: "Pleadings",
    title: "Single Assignment of Error",
    linkSrc: `/samples/1`,
    content:
      "The user copies and pastes the facts, statements of law, and rulings from three judicial decisions. The user also copies and pastes the court's ruling and explains the basis for the assignment of error. An AI attorney analyzes the above information and writes a section of an appellate brief explaining the lower court's alleged error.",
  },
  {
    id: 3,
    heading: "Pleadings",
    title: "Single Issue Legal Memorandums",
    linkSrc: `/samples/7`,
    content:
      "The user copies and pastes the facts, statements of law, and rulings from three judicial decisions. The user also copies and pastes the facts of the case sub judice. An AI attorney analyzes the above information and writes a complete supporting memorandum, includes  introduction, statement of facts, legal analysis, & conclusion.",
  },
  {
    id: 4,
    heading: "Pleadings",
    title: "Statement of Material Facts",
    linkSrc: `/samples/6`,
    content:
      "The user uploads a full-text version of the deposition. An AI attorney will review the deposition page by page and write a series of factual statements discussed on each page.",
  },
  {
    id: 5,
    heading: "Pleadings",
    title: "Written Reasons for Judgment",
    linkSrc: `/samples/8`,
    content:
      "The user uploads the moving party's motion and the non-moving party's opposition memorandum. The user also identifies the legal issue, the relief granted, and the court's reasons for granting said relief. An AI attorney analyzes the above information and writes a written reasons for judgment in conformity with the user's directives.",
  },
];

const ListMedicalDocData = [
  {
    id: 1,
    heading: "Medical",
    title: "Medical Visit Report",
    linkSrc: `/samples/4`,
    content:
      "The user uploads a patient's medical records. An AI medical doctor will review the medical records and create a Word document. The Word document will include a natural language paragraph that summarizes the subjective complaints, diagnoses, and medical treatments. This document is suitable for use in settlement demands.",
  },
  {
    id: 2,
    title: "Medical Visit Summary",
    linkSrc: `/samples/3`,
    content:
      "The user uploads a patient's medical records. An AI medical doctor will review the medical records and create a spreadsheet. The spreadsheet will include the date of the medical visit, subjective complaints, diagnoses, and medical treatments.",
  },
];

const ListStudentsDocData = [
  {
    id: 1,
    heading: "Students",
    title: "IRAC Analysis",
    linkSrc: `/samples/2`,
    content:
      "The user uploads the case under study and identifies the relevant legal issue. An AI attorney will perform an IRAC analysis, identifying the required legal elements and performing an analysis of the legal elements in the uploaded case. ",
  },
];

export default {
  ListPleadingsDocData,
  ListStudentsDocData,
  ListMedicalDocData,
};
