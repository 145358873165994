import React, { useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import ButtonUI from "../components/Button";
import Navbar from "../components/NavBar/";
import { Helmet } from "react-helmet";
import HomeAiCard from "../components/cards/homeAiCard";
import HomeMagicCard from "../components/cards/homeMagicCard";
import HomeAccordion from "../components/accordion";
import { useAuth } from "../App";
import ListDoc from "../components/ListDoc";
import { useDispatch } from "react-redux";
import slices from "../store/user/userSlice";
import axios from "axios";

const HomePage = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const divRef = useRef(null);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(slices.clearUser());
    }

    if (isAuthenticated) {
      const authenticatedUser = async (token) => {
        try {
          const formData = new FormData();
          formData.append("token", token);
          const response = await axios.post(
            process.env.REACT_APP_BACKEND_URL + "authenticatedUser",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data) {
            dispatch(slices.setUser(response.data));
          }
        } catch (error) {}
      };

      authenticatedUser(isAuthenticated);
    }
  }, []);

  const scrollToDiv = () => {
    divRef.current.scrollIntoView({ behavior: "smooth" });
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      style={{
        backgroundColor: "#222131",
      }}
    >
      <Helmet>
        <title>Magic Pleadings</title>
      </Helmet>

      <Box
        sx={{
          height: {
            xs: "800px",
            sm: "500px",
            lg: "590px",
            xl: "100vh",
          },
          backgroundImage: `url('Hero Banner.webp')`,
          backgroundSize: {
            xs: "250% 80%",
            sm: "100% 90%",
            lg: "90% 100%",
            xl: "90% 100%",
          },
          backgroundRepeat: "no-repeat",
          backgroundPosition: { xs: "90% ", sm: "100% 100%" },
          position: "relative",
        }}
      >
        <Navbar />
        <Container
          sx={{
            maxWidth: { xs: "md", xl: 1400 },
            position: "absolute",
            top: { xs: "80%", sm: "50%" },
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: { xs: "center", sm: "left" }, // Center align content
          }}
        >
          <Typography
            color="white"
            fontWeight="bold"
            mt={{ xs: "90%", sm: "10%", xl: "0%" }}
            width={{ xs: "100%", sm: "55%", xl: "55%" }}
            fontSize={{ xs: "65px", sm: "55px", xl: "80px" }}
            fontFamily="Recoletabold"
            lineHeight="1"
          >
            Unlock the <span style={{ color: "#686DFC" }}>Magic of AI</span> in
            your Legal Pleadings
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "20px", xl: "25px" },
              fontFamily: "Recoleta regular",
              color: "#FFFFFF",
              marginTop: "20px",
            }}
          >
            Embracing AI for Document Generation.
          </Typography>
          <ButtonUI text="FAQs" handleClick={scrollToDiv} />
        </Container>
      </Box>

      {isAuthenticated ? <ListDoc /> : ""}
      {!isAuthenticated ? (
        <>
          <Box
            sx={{
              marginTop: { xs: "80%", sm: "0%" },
              marginBottom: { xs: "-60%", sm: "0%" },
              paddingY: { xs: "30%", sm: "5%", lg: "8%", xl: "12%" },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
              position: "relative",
              height: { xs: "300px", sm: "200px", lg: "200px" },
              backgroundImage: `url('magic-board.png')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: { xs: "170%", sm: "50%", xl: "55%" },
              backgroundPosition: { xs: "50% -100%", sm: "center 45%" },
            }}
          >
            <Container
              sx={{
                maxWidth: { xs: "md", xl: 1350 },
              }}
            >
              <Grid
                container
                spacing={4}
                sx={{
                  border: "2px solid white",
                  borderRadius: "10px",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  margin: "0px",
                  width: "100%",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={10}
                  sx={{
                    paddingTop: "10px",
                    paddingBottom: "20px",
                    justifyContent: "center",
                    marginY: "auto",

                    backgroundColor: "#222131",
                    textAlign: { xs: "center", sm: "center" },
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="bold"
                    width={{ xs: "90%" }}
                    fontSize={{ xs: "60px", sm: "50px", xl: "80px" }}
                    fontFamily="Recoletabold"
                    lineHeight="1"
                    variant="h2"
                    sx={{ marginBottom: "30px", marginTop: "15px" }}
                  >
                    Sign up today! Free for limited time.
                  </Typography>
                  <Typography
                    sx={{
                      marginY: "20px",
                      fontFamily: "poppins",
                      fontWeight: 500,
                      fontSize: { xs: "25px", sm: "30px", xl: "35px" },
                      color: "#FFFFFF",
                      width: { xs: "90%", sm: "100%" },
                    }}
                  >
                    Join our growing list of beta testers!
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box
            sx={{
              paddingY: { sm: "12%", lg: "9%", xl: "12%" },
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
              height: { sm: "300px", lg: "300px" },
              backgroundImage: `url('magic-board.png')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: { xs: "180%", sm: "60%", xl: "65%" },
              backgroundPosition: "left center",
            }}
          >
            <Container sx={{ maxWidth: { xs: "md", xl: 1400 } }}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} sx={{ marginY: "auto" }}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "90%" },
                      height: "auto",
                      borderRadius: "20px",
                    }}
                    component="img"
                    src={`Text-Typing-two.gif?fit=crop&auto=format`}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    marginY: "auto",
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="bold"
                    width={{ xs: "100%", sm: "70%" }}
                    fontSize={{ xs: "65px", sm: "55px", xl: "80px" }}
                    fontFamily="Recoletabold"
                    lineHeight="1"
                  >
                    Super Fast
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: 250,
                      fontSize: { xs: "20px", sm: "18px", xl: "25px" },
                      color: "#FFFFFF",
                      width: { xs: "75%", sm: "100%" },
                      marginX: { xs: "auto", sm: "0px" },
                    }}
                  >
                    AI processes information and writes faster than any human.
                    The AI writing to the left is writing in real time.
                  </Typography>
                  <ButtonUI text="READ MORE" />
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              alignItems: "center",
              justifyContent: "center",
              marginTop: { xs: "80%" },
              flexGrow: 1,
              height: {
                xs: "100vh",
                sm: "50vh",
                md: "70vh",
                lg: "100vh",
                xl: "75vh",
              },
            }}
          >
            <Container
              sx={{
                maxWidth: { xs: "md", xl: 1400 },
                backgroundImage: `url('magic-board.png')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: { xs: "100%", sm: "65%", xl: "55%" },
                backgroundPosition: {
                  xs: "100% 0%",
                },
              }}
            >
              <Grid container sx={{ marginY: "auto" }} spacing={4}>
                <Grid item xs={12} sm={6} sx={{ marginY: "auto" }}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "95%" },
                      height: "auto",
                      borderRadius: "20px",
                      marginTop: "-20%",
                    }}
                    component="img"
                    src={`Text-Typing-two.gif?fit=crop&auto=format`}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    marginY: "10%",
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="bold"
                    width={{ xs: "100%", sm: "70%" }}
                    fontSize={{ xs: "55px", sm: "55px", xl: "80px" }}
                    fontFamily="Recoletabold"
                    lineHeight="1"
                  >
                    Super Fast
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "22px", xl: "25px" },
                      color: "#FFFFFF",
                      fontFamily: "poppins",
                      fontWeight: 300,
                      width: { xs: "90%", sm: "90%" },
                      marginX: { xs: "auto", sm: "0px" },
                      marginY: "10px",
                    }}
                  >
                    AI processes information and writes faster than any human.
                    The AI writing to the left is writing in real time.
                  </Typography>
                  <ButtonUI text="READ MORE" />
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
              justifyContent: "center",
              paddingY: { sm: "12%", lg: "8%", xl: "12%" },
              flexGrow: 1,
              backgroundImage: `url('magic-board.png')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: { xs: "180%", sm: "60%", xl: "65%" },
              backgroundPosition: "90% 40%",
            }}
          >
            <Container
              sx={{
                maxWidth: { xs: "md", xl: 1400 },
                display: { xs: "none", sm: "block" },
              }}
            >
              <Grid
                container
                sx={{
                  marginY: "auto",
                }}
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    marginY: "auto",
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="bold"
                    width={{ xs: "100%", sm: "100%" }}
                    fontSize={{ xs: "65px", sm: "55px", xl: "80px" }}
                    fontFamily="Recoletabold"
                    lineHeight="1"
                  >
                    Easy to Use
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: 250,
                      fontSize: { xs: "20px", sm: "18px", xl: "25px" },
                      color: "#FFFFFF",
                      marginTop: "20px",
                      width: { xs: "75%", sm: "100%" },
                      marginX: { xs: "auto", sm: "0px" },
                    }}
                  >
                    Just copy and paste your facts and case law into our
                    pre-made forms and AI will do the rest.{" "}
                  </Typography>
                  <ButtonUI text="READ MORE" />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ marginY: "auto" }}>
                  <Box
                    sx={{
                      margin: "auto",
                      width: { xs: "100%", sm: "90%" },
                      height: "auto",
                      borderRadius: "20px",
                    }}
                    component="img"
                    src={`copy-paste-two.gif?fit=crop&auto=format`}
                  ></Box>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              alignItems: "center",
              justifyContent: "center",
              paddingY: { sm: "2%" },
              flexGrow: 1,
              height: {
                xs: "80vh",
                sm: "50vh",
                md: "70vh",
                lg: "100vh",
                xl: "75vh",
              },
            }}
          >
            <Container
              sx={{
                maxWidth: { xs: "md", xl: 1400 },
                backgroundImage: `url('magic-board.png')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: { xs: "100%", sm: "65%", xl: "55%" },
                backgroundPosition: {
                  xs: "100% 0%",
                },
              }}
            >
              <Grid container sx={{ marginY: "auto" }} spacing={4}>
                <Grid item xs={12} sm={6} sx={{ marginY: "auto" }}>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "95%" },
                      height: "auto",
                      borderRadius: "20px",
                      marginTop: "-20%",
                    }}
                    component="img"
                    src={`copy-paste-two.gif?fit=crop&auto=format`}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    marginY: "10%",
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="bold"
                    width={{ xs: "100%", sm: "70%" }}
                    fontSize={{ xs: "55px", sm: "55px", xl: "80px" }}
                    fontFamily="Recoletabold"
                    lineHeight="1"
                  >
                    Easy to Use
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "22px", xl: "25px" },
                      color: "#FFFFFF",
                      fontFamily: "poppins",
                      fontWeight: 300,
                      width: { xs: "90%", sm: "90%" },
                      marginX: { xs: "auto", sm: "0px" },
                      marginY: "10px",
                    }}
                  >
                    Just copy and paste your facts and case law into our
                    pre-made forms and AI will do the rest.
                  </Typography>
                  <ButtonUI text="READ MORE" />
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingY: { sm: "10%", md: "3%" },
              flexGrow: 1,
              height: "100%",
            }}
          >
            <Container sx={{ maxWidth: { xs: "md", xl: 1400 } }}>
              <Grid container sx={{ marginY: "auto" }} spacing={4}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    marginY: "auto",
                    backgroundImage: `url('magic-board-two.png')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: { xs: "130% ", sm: "100%" },
                    backgroundPosition: {
                      xs: "center center",
                      sm: "center center",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "95%" },
                      height: "auto",
                      paddingTop: { xs: "20%", sm: "0%" },
                    }}
                    component="img"
                    src={`palm-beach-two.gif?fit=crop&auto=format`}
                  ></Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    marginY: "auto",
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="bold"
                    width={{ xs: "100%", sm: "80%" }}
                    fontSize={{ xs: "65px", sm: "55px", xl: "80px" }}
                    fontFamily="Recoletabold"
                    lineHeight="1"
                  >
                    Save Time
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: { xs: 300, sm: 250 },
                      fontSize: { xs: "22px", sm: "18px", xl: "25px" },
                      color: "#FFFFFF",
                      marginTop: "20px",
                      width: { xs: "90%", sm: "100%", xl: "75%" },
                      marginX: { xs: "auto", sm: "0px" },
                    }}
                  >
                    Writing pleadings takes time away from your family, friends,
                    and fun! Magic Pleadings can write in less than 5 minutes
                    what would take two days for an attorney.
                  </Typography>
                  <ButtonUI text="READ MORE" />
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingY: { sm: "3%" },
              flexGrow: 1,
              height: "100%",
            }}
          >
            <Container sx={{ maxWidth: { xs: "md", xl: 1400 } }}>
              <Grid container sx={{ marginY: "auto" }} spacing={0}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    marginTop: { xs: "200px", sm: "0px" },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",

                    height: {
                      sm: "25vh",
                      md: "30vh",
                      lg: "350px",
                      xl: "50vh",
                    },
                    backgroundImage: `url('magic-board-two.png')`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: { xs: "100%", sm: "55%", xl: "40%" },
                    backgroundPosition: {
                      xs: "center center",
                      sm: "center center",
                    },
                    textAlign: "center",
                  }}
                >
                  <Typography
                    color="white"
                    fontWeight="bold"
                    fontSize={{ xs: "60px", sm: "55px", xl: "80px" }}
                    fontFamily="Recoletabold"
                    lineHeight="1"
                  >
                    Sample Pleading
                  </Typography>

                  <Typography
                    color="white"
                    fontSize={{ xs: "30px", sm: "30px", xl: "40px" }}
                    fontFamily="Recoleta"
                    sx={{
                      marginTop: "20px",
                      lineHeight: { xs: "1.5", sm: "1" },
                    }}
                  >
                    Single Issue Supporting Memorandum
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "25px", sm: "20px", xl: "25px" },
                      color: "#FFFFFF",
                      marginY: "20px",
                      fontFamily: "Recoletabold",
                      width: { xs: "70%", sm: "100%" },
                    }}
                  >
                    Written by AI in less than 5 minutes
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    marginY: "auto",
                    marginTop: { xs: "0px", sm: "-60px" },
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "white",
                      height: { xs: "600px", sm: "1000px" },
                      overflow: "scroll",
                      borderRadius: { xs: "20px", sm: "40px" },
                      scrollbarWidth: "none",
                      msOverflowStyle: "none",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                    component="div"
                  >
                    <Box
                      sx={{
                        marginX: { xs: "0%", sm: "10%" },

                        width: { xs: "100%", sm: "80%" },
                      }}
                      component="img"
                      src={`content-Two.png?fit=crop&auto=format`}
                    ></Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      ) : (
        ""
      )}

      <Box
        sx={{
          backgroundColor: "#262535",
          paddingBottom: "10px",
        }}
      >
        <Box
          sx={{
            height: "100%",
          }}
        >
          <Container sx={{ maxWidth: { xs: "md", xl: 1400 } }}>
            <Grid
              container
              sx={{
                paddingTop: isAuthenticated
                  ? "-10px"
                  : { xs: "50%", sm: "10%" },
                textAlign: { xs: "center", sm: "left" },
              }}
              spacing={4}
            >
              {!isAuthenticated ? (
                <>
                  <Grid item xs={12} sm={12}>
                    <Typography
                      sx={{
                        fontSize: { xs: "20px", xl: "30px" },
                        color: "#686DFC",
                        fontFamily: "Recoletabold",
                        width: { xs: "75%", sm: "90%" },
                        marginX: { xs: "auto", sm: "0px" },
                      }}
                    >
                      THE BENEFITS OF
                    </Typography>
                    <Typography
                      color="white"
                      fontWeight="bold"
                      width={{ xs: "100%", sm: "100%" }}
                      fontSize={{ xs: "65px", sm: "70px", xl: "100px" }}
                      fontFamily="Recoletabold"
                      lineHeight="1"
                    >
                      Embracing AI{" "}
                      <Box
                        component="br"
                        sx={{ display: { xs: "none", sm: "block" } }}
                      ></Box>
                      <Box
                        component="span"
                        sx={{
                          fontSize: { xs: "65px", sm: "55px", xl: "85px" },
                        }}
                      >
                        for Document Generation
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <HomeAiCard
                      card={{
                        src: "efficiency-logo.png",
                        title: "Increased Efficiency",
                        content:
                          "AI can generate documents significantly faster than humans, freeing up time for attorneys to focus on more complex tasks.",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <HomeAiCard
                      card={{
                        src: "Accuracy-logo.png",
                        title: "Improved Accuracy",
                        content:
                          "AI reduces the risk of human error, which can be costly in legal practice.",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <HomeAiCard
                      card={{
                        src: "cost-logo.png",
                        title: "Cost Savings",
                        content:
                          "By automating document generation, firms can save on the costs of paralegals and junior attorneys, who typically handle these tasks.",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <HomeAiCard
                      card={{
                        src: "availability-logo.png",
                        title: "24/7 Availability",
                        content: `Unlike human staff, AI tools like "Magic Pleadings" are available around the clock.`,
                      }}
                    />
                  </Grid>
                </>
              ) : (
                ""
              )}
              <Grid item xs={12} sm={12}>
                <Card
                  sx={{
                    marginTop: isAuthenticated
                      ? { xs: "5%", sm: "40px" }
                      : { xs: "50px", sm: "40px" },
                    width: { xs: "85%", sm: "100%" },
                    marginX: "auto",
                    height: "auto",
                    borderRadius: "20px",
                    backgroundColor: "#2B2C40",
                  }}
                >
                  <CardContent sx={{ textAlign: "center", marginY: "1%" }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        color: "white",
                        fontFamily: "Recoleta light",
                        fontSize: { xs: "20px", xl: "33px" },
                        textAlign: "center",
                        padding: "20px",
                      }}
                    >
                      As we move forward, embracing AI in legal practices will
                      become not just an option, but a necessity for remaining
                      competitive and meeting the evolving demands of the
                      profession. The future of legal practice is in AI, and the
                      future is now.
                    </Typography>
                    <ButtonUI text="CONTACT US" />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {!isAuthenticated ? (
          <Box
            sx={{
              height: { xs: "100%", sm: "100%" },
            }}
          >
            <Container sx={{ maxWidth: { xs: "md", xl: 1400 } }}>
              <Grid
                container
                sx={{
                  paddingTop: { xs: "50%", sm: "10%" },
                  textAlign: { xs: "center", sm: "left" },
                  marginBottom: { xs: "50px", sm: "0px" },
                }}
                spacing={2}
              >
                <Grid item xs={12} sm={12}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#686DFC",
                      fontFamily: "Recoletabold",
                      width: { xs: "75%", sm: "90%" },
                      marginX: { xs: "auto", sm: "0px" },
                    }}
                  >
                    WHAT ARE
                  </Typography>
                  <Typography
                    color="white"
                    fontWeight="bold"
                    width={{ xs: "100%", sm: "100%" }}
                    fontSize={{ xs: "65px", sm: "50px", xl: "75px" }}
                    fontFamily="Recoletabold"
                    lineHeight="1"
                  >
                    Magic Pleadings
                    <br style={{ display: { xs: "none", sm: "block" } }} />
                    <Box
                      component="span"
                      sx={{
                        fontSize: { xs: "70px", sm: "80px" },
                      }}
                    >
                      Features
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <HomeMagicCard
                    card={{
                      src: "Cost-Effective-logo.png",
                      title: "Cost-Effective",
                      content:
                        "The solution reduces the need for paralegal or junior associates' time spent on drafting routine legal documents. This translates into significant cost savings for law firms or individual attorneys, allowing them to allocate resources to more strategic or complex tasks.",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <HomeMagicCard
                    card={{
                      src: "Availability-two-logo.png",
                      title: "24/7 Availability",
                      content:
                        "Unlike human assistants, our product is available 24/7. Attorneys can generate legal documents anytime they need them, offering flexibility and ensuring that no time-sensitive opportunities are missed.",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <HomeMagicCard
                    card={{
                      src: "Improve-logo.png",
                      title: "Improve Work-Life Balance",
                      content:
                        "By automating routine legal document creation, attorneys can have more time for other important aspects of their lives. They can spend more time with their families, engage in leisure activities, or learn new skills, leading to a better work-life balance.",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <HomeMagicCard
                    card={{
                      src: "Onboarding-logo.png",
                      title: "Quick Onboarding",
                      content: `Our product is designed to be user-friendly. Even if attorneys are not tech-savvy, they will be able to quickly understand how to use the product and start generating legal documents. This means they won't have to spend a lot of time learning a new tool.`,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <HomeMagicCard
                    card={{
                      src: "Competitive-logo.png",
                      title: "Competitive Advantage",
                      content: `Using AI in your legal practice demonstrates innovation and forward-thinking, which can give you a competitive edge. It can also improve your firm's reputation among clients who value the use of technology in providing legal services.`,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <HomeMagicCard
                    card={{
                      src: "Workflows-logo.png",
                      title: "Streamlined Workflows",
                      content: `Using AI to generate legal documents can significantly reduce the time and effort required to prepare pleadings, arguments, deposition questions, and other legal documents. This allows attorneys to focus more on strategizing and providing personalized services to their clients.`,
                    }}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>
        ) : (
          ""
        )}

        <Box sx={{ height: "100%" }}>
          {!isAuthenticated ? (
            <Grid
              container
              sx={{
                paddingY: { xl: "5%" },
                textAlign: { xs: "center", sm: "left" },
                backgroundColor: "#222131",
              }}
              spacing={2}
            >
              <Grid item xs={12} sm={5}>
                <Box
                  sx={{
                    marginTop: "-90px",
                    width: { xs: "100%", sm: "95%" },
                    height: "auto",
                    paddingTop: { xs: "20%", sm: "20%" },
                  }}
                  component="img"
                  src={`banner-men.png?fit=crop&auto=format`}
                ></Box>
              </Grid>
              <Grid item xs={12} sm={7} sx={{ display: "flex" }}>
                <Typography
                  sx={{
                    borderLeft: { xs: "0px", sm: "2px solid #686DFC" },
                    borderTop: { xs: "2px solid #686DFC", sm: "0px" },
                    width: "80%",
                    marginX: { xs: "auto", sm: "0px" },
                    paddingLeft: { xs: "0px", sm: "20px" },
                    paddingTop: { xs: "10px", sm: "0px" },
                    fontSize: {
                      xs: "20px",
                      sm: "14px",
                      md: "24px",
                      xl: "30px",
                    },
                    color: "#FFFFFF",
                    fontFamily: "Recoleta light",
                    marginY: "auto",
                  }}
                >
                  <span style={{ fontFamily: "Recoletabold" }}>
                    "Magic Pleadings"
                  </span>{" "}
                  is a prime example of an AI solution designed to facilitate
                  document generation. It allows attorneys to upload file
                  materials, which the AI system uses to generate a variety of
                  legal documents, including pleadings, arguments, deposition
                  questions, deposition notices, and jury instructions.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            container
            spacing={2}
            sx={{
              marginY: "auto",
            }}
            ref={divRef}
          >
            <Container sx={{ maxWidth: { xs: "md", xl: 1400 } }}>
              <Grid item xs={12} sm={12}>
                <Typography
                  sx={{
                    display: "block",
                    padding: { xs: "10%", sm: "5%" },
                    fontSize: "60px",
                    color: "#FFFFFF",
                    fontFamily: "Recoletabold",
                    textAlign: "center",
                  }}
                >
                  FAQs
                </Typography>
                <HomeAccordion
                  text={{
                    title:
                      "How does the platform ensure accuracy and relevance of the generated pleadings?",
                    content: `AI systems are not perfect. Attorneys shall and must carefully review all memorandums and citations for substantive accuracy. We cannot control AI generated output. The goal of our system is to speed up the process of typing pleadings. We strive to obtain accurate and relevant results through a process called "prompt engineering" in AI systems. However, we cannot control the quality of the information input into the system, nor the computer generated output either.`,
                  }}
                />
                <HomeAccordion
                  text={{
                    title: "How is sensitive client information protected?",
                    content: `You may only utilize our platform  with information in cases that may be subject to public exposure when filing a pleading with the clerk of court. The information you enter into the system will be transferred through various computer systems outside of our direct control. Do not utilize our platform if information is sealed by court order.`,
                  }}
                />
                <HomeAccordion
                  text={{
                    title: "How are facts and law entered into our platform?",
                    content: `Currently, the proper method of entering information into our system is copy and paste. However, we are actively working on technologies to assist in automatically finding relevant facts and law from depositions, records, expert reports, and case law, respectively.`,
                  }}
                />
                <HomeAccordion
                  text={{
                    title: "Does Magic Pleadings find case law for you?",
                    content: `Our platform is not directly tied to any case law provider, such as Westlaw, FastCase, or Casetext. As the attorney, you must find the two best on-point cases that match your case. However, once you've found your two cases, don't type the information into our system. Just copy and paste the facts, law, and court's analysis into our system. The magic of AI will evaluate the court's ruling and apply the same logic and reason to your case specific facts. Our system will produce extraordinary results in only a few minutes.`,
                  }}
                />
                <HomeAccordion
                  text={{
                    title:
                      "Is there any training required to operate the platform?",
                    content: `You need no training to operate our system. Our system will guide you step by step through the entire process of creating a new pleading. All you need to do is answer a few questions and copy and paste your information into our system.`,
                  }}
                />

                <HomeAccordion
                  text={{
                    title:
                      "How does the system provide me with a copy of the pleadings?",
                    content: `Once the AI drafts your pleading, you will receive a Word file via email.`,
                  }}
                />
                <HomeAccordion
                  text={{
                    title:
                      "Is there a trial period or demo available to test the platform before committing to a subscription?",
                    content: `Yes, our system is currently FREE to use. Magic Pleadings recently launched our initial beta platform MP1.0. We are in the process of testing our system and further refining our processes, so our system is free to use.  `,
                  }}
                />
                <HomeAccordion
                  text={{
                    title: "How much does Magic Pleadings cost?",
                    content: `Magic Pleadings is currently FREE to use, so take advantage of our system! During our beta testing, we will monitor our costs to utilize ChatGPT and determine an appropriate monthly subscription price. `,
                  }}
                />
              </Grid>
            </Container>
          </Grid>
        </Box>
        {!isAuthenticated ? (
          <Box
            sx={{
              height: "100%",
              marginTop: { xs: "20%", sm: "5%" },
              paddingY: { xs: "0%", sm: "5%" },
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid container spacing={2}>
              <Container sx={{ maxWidth: { xs: "md", xl: 1400 } }}>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  sx={{
                    textAlign: { xs: "center", sm: "left" },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "20px", sm: "20px", xl: "25px" },
                      color: { xs: "#686DFC" },
                      fontFamily: "Recoletabold",
                    }}
                  >
                    THE EMERGENCE OF
                  </Typography>
                  <Typography
                    sx={{
                      marginBottom: { xs: "50px", sm: "0px" },
                      width: { xs: "100%", sm: "100%", md: "100%", xl: "80%" },
                      fontSize: { xs: "50px", sm: "100px", xl: "100px" },
                      color: "#FFFFFF",
                      fontFamily: "Recoletabold",
                      lineHeight: 1,
                    }}
                  >
                    AI in Legal Practice
                  </Typography>

                  <Typography
                    sx={{
                      width: { xs: "90%", sm: "110%", md: "80%" },
                      marginX: { xs: "auto", sm: "0px" },
                      fontSize: { xs: "22px", sm: "18px", xl: "30px" },
                      color: "#FFFFFF",
                      fontFamily: "Recoleta light",
                    }}
                  >
                    The legal field has always been document-intensive, with
                    attorneys spending countless hours drafting, reviewing, and
                    refining legal pleadings and other necessary documents. With
                    the advent of AI technologies, however, there is a
                    fundamental shift in the way these tasks are approached. AI,
                    with its ability to process and learn from vast amounts of
                    data, has shown promise in handling complex tasks with
                    greater efficiency and accuracy, including the generation of
                    legal documents.
                  </Typography>
                  <br />
                  <Typography
                    sx={{
                      width: { xs: "90%", sm: "100%", md: "80%" },
                      marginX: { xs: "auto", sm: "0px" },
                      fontSize: { xs: "22px", sm: "18px", xl: "30px" },
                      color: "#FFFFFF",
                      fontFamily: "Recoleta light",
                      textAlign: { xs: "center", sm: "left" },
                    }}
                  >
                    The future of legal practice is here, and it is intertwined
                    with the progressive evolution of AI. The integration of AI
                    in legal document generation is not just a fleeting trend
                    but a sustainable strategy that offers tangible benefits.
                    Tools like "Magic Pleadings" symbolize this future, where
                    attorneys leverage AI to increase efficiency, improve
                    accuracy, and provide better services to their clients.
                  </Typography>
                  <Link to={"/register"}>
                    <Button
                      variant="outlined"
                      sx={{
                        marginTop: "5%",
                        padding: { xs: "10px 25px", xl: "20px 50px" },
                        color: "white",
                        fontSize: { xs: "10px", xl: "15px" },
                        borderColor: "white",
                        border: "2px solid",
                        "&:hover": {
                          border: "2px solid",
                          borderColor: "rgba(104, 109, 252, 0.7)",
                          color: "rgba(104, 109, 252, 0.7)",
                        },
                      }}
                    >
                      TRY THE MAGIC
                    </Button>
                  </Link>
                </Grid>
              </Container>

              <Box
                sx={{
                  bottom: { xs: 0, sm: 0, md: 0 },
                  right: { xs: 0, sm: 0 },
                  position: { xs: "normal", sm: "absolute" },
                  width: { xs: "100%", sm: "50%" },
                  height: { xs: "50vh", sm: "100%" },
                  backgroundImage: `url('banner-hands4.png')`,
                  backgroundSize: { xs: "80%", sm: "75%", xl: "80%" },
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center bottom",
                }}
                component="div"
              ></Box>
            </Grid>

            {/* <div style={{ height: "100%" }}>
              <div
                style={{
                  position: "absolute",
                  bottom: -50,
                  left: 0,
                  width: "100%",
                  height: "40%",
                  // opacity: "0.3",
                  backdropFilter: "blur(10px)", // Adjust the blur intensity as needed
                  background:
                    "linear-gradient(90deg, rgba(144, 248, 118, 0.45) 0%, rgba(70, 239, 239, 0.302687) 23.13%, rgba(104, 109, 252, 0.45) 45.95%, rgba(130, 68, 192, 0.45) 75.37%, rgba(243, 105, 105, 0.45) 100%)",
                }}
              ></div>
            </div> */}
          </Box>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
};

export default HomePage;
