const IframePleadingsData = [
  {
    id: 1,
    title: "Single Assignment of Error",
    iframeSrc: "https://www.cognitoforms.com/f/1lc-iafvYUSzPa3loQlj8g/5",
  },

  {
    id: 2,
    title: "Single Issue Memorandum in Support",
    iframeSrc: "https://www.cognitoforms.com/f/1lc-iafvYUSzPa3loQlj8g/1",
  },
  {
    id: 3,
    title: "Memorandum in Support of Motion for Reconsideration",
    iframeSrc: "https://www.cognitoforms.com/f/1lc-iafvYUSzPa3loQlj8g/6",
  },
  {
    id: 4,
    title: "Statement of Material Facts",
    iframeSrc: "https://www.cognitoforms.com/f/1lc-iafvYUSzPa3loQlj8g/20",
  },
  {
    id: 5,
    title: "Written Reasons for Judgment",
    iframeSrc: "https://www.cognitoforms.com/f/1lc-iafvYUSzPa3loQlj8g/10",
  },
];

const IframeStudentData = [
  {
    id: 1,
    title: "Case Law IRAC Analysis",
    iframeSrc: "https://www.cognitoforms.com/f/1lc-iafvYUSzPa3loQlj8g/2",
  },
];

const IframeMedicalData = [
  {
    id: 1,
    title: "Medical Visit Summary",
    iframeSrc: "https://www.cognitoforms.com/f/1lc-iafvYUSzPa3loQlj8g/17",
  },
  {
    id: 2,
    title: "Medical Visit Report",
    iframeSrc: "https://www.cognitoforms.com/f/1lc-iafvYUSzPa3loQlj8g/24",
  },
];

export default { IframeStudentData, IframePleadingsData, IframeMedicalData };
